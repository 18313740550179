/* thai */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a0699cace6f22665-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1b86bbb0e0aba1f4-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a8cee68ffb1b6564-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/64a52b4e53bd2712-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/faece7604e15c02a-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/456cfd944351cca1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a0ee15cef67cf8dc-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c0e7ae020604e3a3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/cb3824f1d7eb6ced-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f70e04fec95eb4ee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7d82a10ce072b2a1-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/5f23b7e296a29a0b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ab03b37c15855205-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a7610dba1678ee1d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8158c7bae1960491-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/daacbd8d426571ce-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/186afecab029f299-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/72265cbb84e754a6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/0485280d42c00163-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/54eb3a928fcf623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sarabun_Fallback_5c81bf';src: local("Arial");ascent-override: 109.45%;descent-override: 23.78%;line-gap-override: 0.00%;size-adjust: 97.58%
}.__className_5c81bf {font-family: '__Sarabun_5c81bf', '__Sarabun_Fallback_5c81bf';font-style: normal
}

/* thai */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a0699cace6f22665-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1b86bbb0e0aba1f4-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a8cee68ffb1b6564-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/64a52b4e53bd2712-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/faece7604e15c02a-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/456cfd944351cca1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a0ee15cef67cf8dc-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c0e7ae020604e3a3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/cb3824f1d7eb6ced-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f70e04fec95eb4ee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7d82a10ce072b2a1-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/5f23b7e296a29a0b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ab03b37c15855205-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a7610dba1678ee1d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8158c7bae1960491-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/daacbd8d426571ce-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/186afecab029f299-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/72265cbb84e754a6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/0485280d42c00163-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_5c81bf';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/54eb3a928fcf623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sarabun_Fallback_5c81bf';src: local("Arial");ascent-override: 109.45%;descent-override: 23.78%;line-gap-override: 0.00%;size-adjust: 97.58%
}.__className_5c81bf {font-family: '__Sarabun_5c81bf', '__Sarabun_Fallback_5c81bf';font-style: normal
}

